
import { Component, Prop } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import { SelectItem } from '@/interfaces/components/SelectItem';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { convertEmptyToNull } from '@/util/helper';

@Component({
  components: { VFormBuilder },
})
export default class SlotForm extends mixins(StackedForm) {
  @Prop({ type: Boolean, default: false }) public disableTerminal!: boolean;
  @Prop({ type: Boolean, default: false }) public disableStandard!: boolean;
  @Prop({ type: Boolean, default: false }) public disableSlotActive!: boolean;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
    standard: InstanceType<typeof VFormBuilder> & { form: any };
    preorder: InstanceType<typeof VFormBuilder> & { form: any };
    terminal: InstanceType<typeof VFormBuilder> & { form: any };
    catering: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get slotItems() {
    return [
      {
        name: 'active',
        type: InputType.Checkbox,
        label: 'venue.form.active',
        default: false,
        disabled: this.disableSlotActive,
      },
      {
        name: 'interval',
        type: InputType.Select,
        items: this.intervals,
        label: 'venue.form.interval',
        default: 5,
        disabled: this.disabledCallback,
      },
      {
        name: 'readyOffset',
        type: InputType.Select,
        items: this.intervals,
        label: 'venue.form.readyOffset',
        default: 0,
        disabled: this.disabledCallback,
      },
      {
        name: 'readyOffsetDelivery',
        type: InputType.Select,
        items: this.intervals,
        label: 'venue.form.readyOffsetDelivery',
        default: 0,
        disabled: this.disabledCallback,
      },
      {
        name: 'notPickedOffset',
        type: InputType.Text,
        rules: 'integer',
        default: 0,
        label: 'venue.form.notPickedOffset',
        disabled: this.disabledCallback,
      },
      {
        name: 'reserved',
        type: InputType.Text,
        rules: 'integer',
        label: 'venue.form.reserved',
        default: 0,
        disabled: this.disabledCallback,
      },
      {
        name: 'max',
        type: InputType.Text,
        rules: 'integer',
        label: 'venue.form.max',
        default: 0,
        disabled: this.disabledCallback,
      },
      {
        name: 'calculateDays',
        type: InputType.Text,
        rules: 'integer|min_value:1|max_value:20',
        label: 'venue.form.calculateDays',
        default: 7,
        disabled: this.disabledCallback,
      },
    ];
  }

  public disabledCallback(form: { active: boolean }) {
    return !form.active;
  }

  get standard() {
    return [
      {
        name: 'standard',
        type: InputType.Text,
        label: 'venue.form.standardSlot',
      },
    ];
  }

  get terminal() {
    return [
      {
        name: 'inside',
        type: InputType.Text,
        label: 'venue.form.insideSlot',
      },
      {
        name: 'takeAway',
        type: InputType.Text,
        label: 'venue.form.takeAwaySlot',
      },
    ];
  }

  get preorder() {
    return [
      {
        name: 'inside',
        type: InputType.Text,
        label: 'venue.form.insideSlot',
      },
      {
        name: 'takeAway',
        type: InputType.Text,
        label: 'venue.form.takeAwaySlot',
      },
      {
        name: 'delivery',
        type: InputType.Text,
        label: 'venue.form.deliverySlot',
      },
    ];
  }

  get catering() {
    return [
      {
        name: 'takeAway',
        type: InputType.Text,
        label: 'venue.form.takeAwaySlot',
      },
      {
        name: 'delivery',
        type: InputType.Text,
        label: 'venue.form.deliverySlot',
      },
    ];
  }

  get intervals() {
    const intervals: SelectItem[] = [];
    for (let i = 5; i <= 60; i += 5) {
      intervals.push({ value: i, text: i.toString() });
    }

    return intervals;
  }

  public getData() {
    const data: any = {
      ...this.$refs.form.form,
      preorder: {
        ...convertEmptyToNull(this.$refs.preorder.form),
      },
    };

    if (!this.disableStandard) {
      data.standard = this.$refs.standard.form.standard === '' ? null : this.$refs.standard.form.standard;
    }
    if (!this.disableTerminal) {
      data.terminal = { ...convertEmptyToNull(this.$refs.terminal.form) };
    }

    data.catering = { ...convertEmptyToNull(this.$refs.catering.form) };

    return { slot: data };
  }
}
